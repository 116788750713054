<template>
  <div class="main-content">
    <h3 class="marginBottom20">{{ `${type === 'create' ? '新增' : type === 'edit' ? '编辑' : '查看'}办公租赁` }}</h3>

    <el-card>
      <el-steps :active="active" finish-status="success" simple>
        <el-step title="基础信息" ></el-step>
        <el-step title="房屋信息" ></el-step>
        <el-step title="房源资料" ></el-step>
        <el-step v-if="disabled && createFour.isSign === 1" title="签订挂牌委托协议" ></el-step>
      </el-steps>

      <CreateOne ref="createOne" v-show="active === 0" @onload="onload"/>
      <CreateTwo ref="createTwo" v-show="active === 1"/>
      <CreateThree ref="createThree" v-show="active === 2"/>
      <CreateFour ref="createFour" v-show="active === 3"/>
      <div class="flex align-items justify-content-center marginTop20">
        <!--        上一步-->
        <el-button size="small" class="marginRight10" v-if="active > 0" @click="prev(active - 1)">上一步</el-button>
        <!--        下一步-->
        <el-button size="small" type="primary" v-if="active < 2 && active >= 0" @click="next(active + 1)">下一步</el-button>
        <!--        提交-->
        <el-button size="small" type="primary" :disabled="submitType" v-if="active === 2 && !disabled" @click="submit">提交</el-button>
        <el-button size="small" type="primary" v-if="active === 2 && disabled && createFour.isSign === 1" @click="next(active + 1)">下一步</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import CreateOne from './components/createOne.vue';
import CreateTwo from './components/createTwo.vue';
import CreateThree from './components/createThree.vue';
import CreateFour from './components/createFour.vue';
import { add, update, view } from "@/api/house/tenancy";
export default {
  name: "create",
  components: {
    CreateOne,
    CreateTwo,
    CreateThree,
    CreateFour
  },
  data(){
    return{
      id: this.$route.query.id,
      disabled: this.$route.query.type === 'view',
      type: this.$route.query.type,
      active: 0,
      createOne: {},
      createTwo: {},
      createThree: {},
      createFour: {},
      submitType: false, // 阻止重复提交
    }
  },
  methods: {
    async onload(){
      await this.getView();
    },
    async getView(){
      if(this.id){
        const { data } = await view({
          id: this.id
        });
        console.log(data);
        this.createOne = {
          title: data.title,
          cityId: data.cityId,
          countyId: data.countyId,
          businessId: data.businessId,
          buildId: data.buildId,
          buildNum: data.buildNum,
          unitNumber: data.unitNumber,
          isUnit: data.isUnit,
          houseNumber: data.houseNumber,
          isHouse: data.isHouse,
          rentalMode: data.rentalMode,
          bedroom: data.bedroom,
          office: data.office,
          toilet: data.toilet,
          rentalArea: data.rentalArea,
          expectPrice: data.expectPrice,
          buildYear: data.buildYear,
          pledge: data.pledge,
          pay: data.pay,
          labelIds: data.labelIds

        }
        this.createTwo = {
          orientation: data.orientation,
          floorCurrent: data.floorCurrent,
          floorTotal: data.floorTotal,
          elevator: data.elevator,
          checkType: data.checkType,
          checkMonth: data.checkMonth,
          facilities: data.facilities,
          heatMode: data.heatMode,
          isHardcover: data.isHardcover,
          hydropower: data.hydropower,

          businessType:data.businessType,
          totalFloor:data.totalFloor,
          floorHeight:data.floorHeight,
          singleLayerArea:data.singleLayerArea,
          netLayerHeight:data.netLayerHeight,
          parkSpace:data.parkSpace,
          airConditionType:data.airConditionType,
          propertyFees:data.propertyFees,
          propertyType:data.propertyType,
          developer:data.developer,
          propertyManagement:data.propertyManagement
        }
        this.createThree = {
          images: data.images,
          houseOwner: data.houseOwner,
          phone: data.phone,
          idCard: data.idCard,
          idCardFront: data.idCardFront || '',
          idCardBack: data.idCardBack || '',
          premisesPermit: data.premisesPermit
        }
        this.createFour = {
          signType: data.signType,
          signTypeImage: data.signTypeImage || '',
          contractId: data.contractId || '',
          isSign: data.isSign,
        }
        await this.$refs.createOne.setForm(this.createOne);
        await this.$refs.createTwo.setForm(this.createTwo);
        await this.$refs.createThree.setForm(this.createThree);
        await this.$refs.createFour.setForm(this.createFour);
      }
    },
    async next(active){
      if(active === 1){
        const createOne = await this.$refs.createOne.formSubmit();
        if(createOne){
          this.active = active;
          this.createOne = createOne;
          // console.log(this.createOne);
        }
      }else if(active === 2){
        const createTwo = await this.$refs.createTwo.formSubmit();
        // console.log(2222,createTwo);
        if(createTwo){
          this.active = active;
          const { orientation,facilities,...rest } = createTwo;
          const orientationArr = orientation.filter(item => item);
          const facilitiesArr = facilities.filter(item => item);
          this.createTwo = {
            orientation: orientationArr.toString(),
            facilities: facilitiesArr.toString(),
            ...rest
          };
          // console.log(this.createTwo);
        }
      }else if(active === 3){
        const createThree = await this.$refs.createThree.formSubmit();
        // console.log(3333,createThree);
        if(createThree){
          this.active = active;
          this.createThree = createThree;
        }
      }
    },
    async prev(e){
      this.active = e;
    },
    async submit(){
      const createThree = await this.$refs.createThree.formSubmit();
      // console.log(3333,createThree);
      if(createThree){
        this.createThree = createThree;
        this.submitType = true;
        // console.log('this.createOne',this.createOne);
        // console.log('this.createTwo',this.createTwo);
        // console.log('this.createThree',this.createThree);
        if(this.id){
          update({
            id: this.id,
            ...this.createOne,
            ...this.createTwo,
            ...this.createThree
          }).then(res => {
            if(res.code === 200){
              this.$message.success('修改成功');
              this.$router.go(-1)
            }else{
              this.$message.error(res.msg);
              this.submitType = false;
            }
          })
        }else{
          add({
            ...this.createOne,
            ...this.createTwo,
            ...this.createThree
          }).then(res => {
            if(res.code === 200){
              this.$message.success('添加成功');
              this.$router.go(-1)
            }else{
              this.$message.error(res.msg);
              this.submitType = false;
            }
          })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>