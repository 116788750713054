<template>
  <div class="marginTop20">
    <avue-form ref="form"
               v-model="form"
               :option="formOption">
      <template slot="checkMonth">
        <el-input v-model="form.checkMonth"
                  placeholder="请输入 月份"
                  :disabled="disabled"
                  @blur="changeBlur($event,'checkMonth',999)"
                  onkeyup="this.value=this.value.replace(/^(0+)|\D+/g,'')">
          <template slot="append">个月后</template>
        </el-input>
      </template>
      <template slot="totalFloor">
        <el-input v-model="form.totalFloor"
                  placeholder="请输入 总楼层"
                  :disabled="disabled"
                  clearable
                  @blur="changeBlur($event,'totalFloor',999999,'decimal')"
                  onkeyup="this.value=this.value.match(/^[1-9]\d*$/)">
          <template slot="append">层</template>
        </el-input>
      </template>
      <template slot="floorHeight">
        <el-input v-model="form.floorHeight"
                  placeholder="请输入 层高"
                  :disabled="disabled"
                  clearable
                  @blur="changeBlur($event,'floorHeight',999999,'decimal')"
                  onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
          <template slot="append">米</template>
        </el-input>
      </template>
      <template slot="singleLayerArea">
        <el-input v-model="form.singleLayerArea"
                  placeholder="请输入 单层面积"
                  :disabled="disabled"
                  clearable
                  @blur="changeBlur($event,'singleLayerArea',999999,'decimal')"
                  onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
          <template slot="append">m²</template>
        </el-input>
      </template>
      <template slot="netLayerHeight">
        <el-input v-model="form.netLayerHeight"
                  placeholder="请输入 净层高"
                  :disabled="disabled"
                  clearable
                  @blur="changeBlur($event,'netLayerHeight',999999,'decimal')"
                  onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
          <template slot="append">米</template>
        </el-input>
      </template>
      <!-- <template slot="propertyFees">
        <el-input v-model="form.propertyFees"
                  placeholder="请输入 物业费"
                  clearable
                  @blur="changeBlur($event,'propertyFees',999999,'decimal')"
                  onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
          <template slot="append">元/m²·月</template>
        </el-input>
      </template> -->
    </avue-form>
  </div>
</template>

<script>
import { floor,facilities } from './floor';
export default {
  name: "createTwo",
  data(){
    return{
      disabled: this.$route.query.type === 'view',
      form: {
        checkMonth: '',
        totalFloor:'',
        floorHeight:'',
        singleLayerArea:'',
        netLayerHeight:'',
        propertyFees:'',
      },
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 200,
        disabled: this.$route.query.type === 'view',
        column: [
          {
            label: '朝向',
            prop: 'orientation',
            type: 'checkbox',
            dicData: [
              {
                label: '东',
                value: 1
              },
              {
                label: '南',
                value: 2
              },
              {
                label: '西',
                value: 3
              },
              {
                label: '北',
                value: 4
              }
            ],
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择朝向",
              trigger: "blur"
            }]
          },
          {
            label: '楼层',
            prop: 'floorCurrent',
            type: 'select',
            dicData: floor,
            props: {
              label: 'label',
              value: 'label'
            },
            span: 8,
            rules: [{
              required: true,
              message: "请选择当前楼层",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(value === ''){
                  callback(new Error('请选择当前楼层'));
                }else if(value > this.form.floorTotal){
                  callback(new Error('当前楼层不能大于总楼层'));
                }else{
                  callback();
                }
              },
              trigger: "change"
            }],
            change: (e) => {
              if(e){
                if(e.value < this.form.floorTotal){
                  this.$refs.form.clearValidate(`floorCurrent`);
                  this.$refs.form.clearValidate(`floorTotal`);
                }
              }
            }
          },
          {
            label: '',
            labelWidth: 0,
            prop: 'floorTotal',
            type: 'select',
            dicData: floor,
            props: {
              label: 'label',
              value: 'label'
            },
            span: 5,
            row: true,
            placeholder: '请选择总楼层',
            rules: [{
              required: true,
              message: "请选择总楼层",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(value === ''){
                  callback(new Error('请选择总楼层'));
                }else if(value < this.form.floorCurrent){
                  callback(new Error('总楼层不能小于当前楼层'));
                }else{
                  callback();
                }
              },
              trigger: "change"
            }],
            change: (e) => {
              if(e){
                if(e.value > this.form.floorCurrent){
                  this.$refs.form.clearValidate(`floorCurrent`);
                  this.$refs.form.clearValidate(`floorTotal`);
                }
              }
            }
          },
          {
            label: '电梯',
            prop: 'elevator',
            maxlength: 30,
            showWordLimit: true,
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请输入电梯",
              trigger: "blur"
            }]
          },
          // {
          //   label: '电梯',
          //   prop: 'elevator',
          //   type: 'radio',
          //   dicData: [
          //     {
          //       label: '无',
          //       value: 1
          //     },
          //     {
          //       label: '有',
          //       value: 2
          //     }
          //   ],
          //   span: 13,
          //   row: true,
          //   rules: [{
          //     required: true,
          //     message: "请选择有无电梯",
          //     trigger: "blur"
          //   }]
          // },
          {
            label: '入住',
            prop: 'checkType',
            type: 'radio',
            dicData: [
              {
                label: '随时入住',
                value: 1
              },
              {
                label: '其他',
                value: 2
              }
            ],
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择入住时间",
              trigger: "blur"
            }]
          },
          {
            label: '',
            prop: 'checkMonth',
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请输入几个月后入住",
              trigger: "blur"
            }],
            display: false
          },
          {
            label: '设施',
            prop: 'facilities',
            type: 'checkbox',
            dicData: facilities,
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择设施",
              trigger: "blur"
            }]
          },
          {
            label: '供暖方式',
            prop: 'heatMode',
            type: 'radio',
            dicData: [
              {
                label: '无',
                value: 1
              },
              {
                label: '自供暖',
                value: 2
              },
              {
                label: '集中供暖',
                value: 3
              }
            ],
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择供暖方式",
              trigger: "blur"
            }]
          },
          {
            label: '是否精装',
            prop: 'isHardcover',
            type: 'radio',
            dicData: [
              {
                label: '否',
                value: 2
              },
              {
                label: '是',
                value: 1
              }
            ],
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择是否精装",
              trigger: "blur"
            }]
          },
          {
            label: '水电',
            prop: 'hydropower',
            maxlength: 6,
            showWordLimit: true,
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请输入水电",
              trigger: "blur"
            }]
          },
          {
            label: '业态类型',
            prop: 'businessType',
            type: 'radio',
            dicData: [
              {
                label: '写字楼',
                value: 1
              },
              {
                label: '创意园区',
                value: 2
              },
              {
                label: '共享办公',
                value: 3
              }
            ],
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择业态类型",
              trigger: "blur"
            }]
          },
          {
            label: '总楼层',
            prop: 'totalFloor',
            maxlength: 8,
            showWordLimit: true,
            span: 13,
            row: true,
          },
          {
            label: '层高',
            prop: 'floorHeight',
            maxlength: 8,
            showWordLimit: true,
            span: 13,
            row: true,
          },
          {
            label: '单层面积',
            prop: 'singleLayerArea',
            maxlength: 8,
            showWordLimit: true,
            span: 13,
            row: true,
          },
          {
            label: '净层高',
            prop: 'netLayerHeight',
            maxlength: 8,
            showWordLimit: true,
            span: 13,
            row: true,
          },
          {
            label: '停车位',
            prop: 'parkSpace',
            maxlength: 20,
            showWordLimit: true,
            span: 13,
            row: true,
          },
          {
            label: '空调类型',
            prop: 'airConditionType',
            maxlength: 8,
            showWordLimit: true,
            span: 13,
            row: true,
          },
          {
            label: '物业费',
            prop: 'propertyFees',
            maxlength: 8,
            showWordLimit: true,
            span: 13,
            row: true,
          },
          {
            label: '楼盘类型',
            prop: 'propertyType',
            maxlength: 8,
            showWordLimit: true,
            span: 13,
            row: true,
          },
          {
            label: '开发商',
            prop: 'developer',
            maxlength: 20,
            showWordLimit: true,
            span: 13,
            row: true,
          },
          {
            label: '物业公司',
            prop: 'propertyManagement',
            maxlength: 20,
            showWordLimit: true,
            span: 13,
            row: true,
          },


        ]
      }
    }
  },
  watch: {
    'form.checkType': {
      handler(val) {
        const checkMonth = this.findObject(this.formOption.column, 'checkMonth');
        if(val === 1){
          checkMonth.display = false;
        }else if(val === 2){
          checkMonth.display = true;
        }
      },
      deep: true
    },
  },
  methods: {
    async setForm(form) {
      // console.log(form)
      // console.log(JSON.parse(form.orientation))
      const { orientation,facilities, ...rest } = form
      const orientationArr = orientation.split(',').map(item => {
        return Number(item)
      })
      const facilitiesArr = facilities.split(',').map(item => {
        return Number(item)
      })
      this.form = {
        ...rest,
        orientation: orientationArr,
        facilities: facilitiesArr,
      };
    },
    formSubmit(){
      return new Promise(resolve => {
        this.$refs.form.validate((valid,done) => {
          if (valid) {
            done();
            resolve(this.form)
          } else {
            done();
            resolve(false)
          }
        });
      })
    },
    // 限制输入框
    changeBlur(e,text,max){
      // console.log(e.target.value,text)
      // console.log(isNaN(e.target.value))
      const value = e.target.value;
      if(value){
        if(isNaN(e.target.value)){
          e.target.value = ''
          this.form[text] = ''
        }else{
          // console.log(Number(value))
          if(Number(value) > Number(max)){
            this.form[text] = max
          }else{
            this.form[text] = value
          }
        }
      }else{
        e.target.value = '';
        this.form[text] = ''
      }
    },
  }
}
</script>

<style scoped>

</style>