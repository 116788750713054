<template>
  <div class="paddingTop20">
    <avue-form ref="form"
               v-model="form"
               v-loading="loading"
               :option="formOption">

      <template slot="cityId">
        <el-select :disabled="disabled" v-model="form.cityId" clearable @change="handleCityId">
          <el-option v-for="(item,index) in cityList" :key="index" :label="item.name" :value="item.id" />
        </el-select>
      </template>

      <template slot="countyId">
        <el-select :disabled="disabled" v-model="form.countyId" clearable @change="handleCountyId">
          <el-option v-for="(item,index) in countyList" :key="index" :label="item.name" :value="item.id" />
        </el-select>
      </template>

      <template slot="businessId">
        <el-select :disabled="(!form.countyId && !disabled) || disabled" v-model="form.businessId" clearable @change="handleBusinessId">
          <el-option v-for="(item,index) in businessList" :key="index" :label="item.name" :value="item.id" />
        </el-select>
      </template>

      <template slot="buildId">
        <el-select :disabled="(!form.businessId && !disabled) || disabled" filterable v-model="form.buildId" clearable>
          <el-option v-for="(item,index) in buildIdList" :key="index" :label="item.name" :value="item.id" />
        </el-select>
      </template>

      <template slot="unitNumber">
        <div class="flex align-items justify-content-space-between">
          <div class="width_60">
            <el-input placeholder="请输入单元号"
                      v-if="!disabled"
                      v-model="form.unitNumber"
                      maxlength="20"
                      :disabled="form.isUnit === 2"
                      clearable
                      :show-word-limit="true"/>
            <el-input placeholder="请输入单元号"
                      v-if="disabled"
                      v-model="form.unitNumber"
                      maxlength="20"
                      :disabled="disabled"
                      clearable
                      :show-word-limit="true"/>
          </div>
          <div>
            <el-checkbox v-if="!disabled" v-model="form.isUnit" :disabled="form.unitNumber !== ''" :true-label="2" :false-label="1" label="无单元号" @change="handleIsUnit"/>
            <el-checkbox v-if="disabled" v-model="form.isUnit" :disabled="true" :true-label="2" :false-label="1" label="无单元号"/>
            <el-tooltip class="item" effect="dark" content="楼栋没有划分具体单元，或者特殊的房屋（别墅、四合院、平房简楼）没有单元号，请选择“无单元号”" placement="right">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
        </div>
      </template>

      <template slot="houseNumber">
        <div class="flex align-items justify-content-space-between">
          <div class="width_60">
            <el-input placeholder="请输入门牌号"
                      v-if="!disabled"
                      v-model="form.houseNumber"
                      maxlength="20"
                      :disabled="form.isHouse === 2"
                      clearable
                      :show-word-limit="true"/>
            <el-input placeholder="请输入门牌号"
                      v-if="disabled"
                      v-model="form.houseNumber"
                      maxlength="20"
                      :disabled="disabled"
                      clearable
                      :show-word-limit="true"/>
          </div>
          <div>
            <el-checkbox v-if="!disabled" v-model="form.isHouse" :disabled="form.houseNumber !== ''" :true-label="2" :false-label="1" label="无门牌号"  @change="handleIsHouse"/>
            <el-checkbox v-if="disabled" v-model="form.isHouse" :disabled="true" :true-label="2" :false-label="1" label="无门牌号"/>
            <el-tooltip class="item" effect="dark" content="一般情况下，特殊的房屋，例如别墅、四合院、平房简楼没有门牌号，请选择“无门牌号”" placement="right">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
        </div>
      </template>

      <template slot="bedroomOffice">
        <div class="flex align-items">
          <div class="width_30 flex">
            <el-select v-model="form.bedroom" clearable @change="changeBedroom"
                       :disabled="disabled">
              <el-option :value="1" label="1"></el-option>
              <el-option :value="2" label="2"></el-option>
              <el-option :value="3" label="3"></el-option>
              <el-option :value="4" label="4"></el-option>
              <el-option :value="5" label="5"></el-option>
              <el-option :value="6" label="6"></el-option>
              <el-option :value="7" label="7"></el-option>
              <el-option :value="8" label="8"></el-option>
              <el-option :value="9" label="9"></el-option>
              <el-option :value="10" label="10"></el-option>
            </el-select>
            <el-button type="text" style="color: #909399;padding: 0 10px">室</el-button>
          </div>
          <div class="width_30 flex">
            <el-select v-model="form.office" clearable @change="changeOffice"
                       :disabled="disabled">
              <el-option :value="0" label="0"></el-option>
              <el-option :value="1" label="1"></el-option>
              <el-option :value="2" label="2"></el-option>
              <el-option :value="3" label="3"></el-option>
              <el-option :value="4" label="4"></el-option>
              <el-option :value="5" label="5"></el-option>
              <el-option :value="6" label="6"></el-option>
              <el-option :value="7" label="7"></el-option>
              <el-option :value="8" label="8"></el-option>
              <el-option :value="9" label="9"></el-option>
              <el-option :value="10" label="10"></el-option>
            </el-select>
            <el-button type="text" style="color: #909399;padding: 0 10px">厅</el-button>
          </div>
          <div class="width_30 flex">
            <el-select v-model="form.toilet" clearable @change="changeToilet"
                       :disabled="disabled">
              <el-option :value="0" label="0"></el-option>
              <el-option :value="1" label="1"></el-option>
              <el-option :value="2" label="2"></el-option>
              <el-option :value="3" label="3"></el-option>
              <el-option :value="4" label="4"></el-option>
              <el-option :value="5" label="5"></el-option>
              <el-option :value="6" label="6"></el-option>
              <el-option :value="7" label="7"></el-option>
              <el-option :value="8" label="8"></el-option>
              <el-option :value="9" label="9"></el-option>
              <el-option :value="10" label="10"></el-option>
            </el-select>
            <el-button type="text" style="color: #909399;padding: 0 10px">卫</el-button>
          </div>
        </div>
      </template>

      <template slot="rentalArea">
        <el-input v-model="form.rentalArea"
                  placeholder="请输入 出租面积"
                  :disabled="disabled"
                  @blur="changeBlur($event,'rentalArea',999999)"
                  onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
          <template slot="append">平米</template>
        </el-input>
      </template>

      <template slot="expectPrice">
        <el-input v-model="form.expectPrice"
                  placeholder="请输入 期望租金"
                  :disabled="disabled"
                  @blur="changeBlur($event,'expectPrice',999999)"
                  onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
          <template slot="append">元/㎡/天</template>
        </el-input>
      </template>

      <template slot="buildYear">
        <el-date-picker
            v-model="form.buildYear"
            type="year"
            :picker-options="pickerOptions"
            :disabled="disabled"
            value-format="yyyy"
            placeholder="请选择年份">
        </el-date-picker>
        <el-button type="text" style="color: #ebb563">实际房龄以政府信息为准</el-button>
      </template>

      <template slot="pledgePay">
        <div class="flex align-items">
          <div class="width_30 flex">
            <el-button type="text" style="color: #909399;padding: 0 10px">押</el-button>
            <el-select v-model="form.pledge" clearable @change="changePledge"
                       :disabled="disabled">
              <el-option :value="1" label="1"></el-option>
              <el-option :value="2" label="2"></el-option>
              <el-option :value="3" label="3"></el-option>
              <el-option :value="4" label="4"></el-option>
              <el-option :value="5" label="5"></el-option>
              <el-option :value="6" label="6"></el-option>
              <el-option :value="7" label="7"></el-option>
              <el-option :value="8" label="8"></el-option>
              <el-option :value="9" label="9"></el-option>
              <el-option :value="10" label="10"></el-option>
            </el-select>
          </div>
          <div class="width_30 flex">
            <el-button type="text" style="color: #909399;padding: 0 10px">付</el-button>
            <el-select v-model="form.pay" clearable @change="changePay"
                       :disabled="disabled">
              <el-option :value="1" label="1"></el-option>
              <el-option :value="2" label="2"></el-option>
              <el-option :value="3" label="3"></el-option>
              <el-option :value="4" label="4"></el-option>
              <el-option :value="5" label="5"></el-option>
              <el-option :value="6" label="6"></el-option>
              <el-option :value="7" label="7"></el-option>
              <el-option :value="8" label="8"></el-option>
              <el-option :value="9" label="9"></el-option>
              <el-option :value="10" label="10"></el-option>
            </el-select>
          </div>
        </div>
      </template>
    </avue-form>
  </div>
</template>

<script>
import {listBuild, listBusiness, listCity, listCounty} from "@/api/house/second";
import { listLabel } from "@/api/house/tenancy"

export default {
  name: "createOne",
  data(){
    return{
      form: {
        title: '',
        cityId: '',
        countyId: '',
        businessId: '',
        buildId: '',
        buildNum: '',
        unitNumber: '',
        isUnit: 1,
        houseNumber: '',
        isHouse: 1,
        bedroomOffice: '',
        bedroom: '',
        office: '',
        toilet: '',
        rentalArea: '',
        expectPrice: '',
        buildYear: '',
        pledgePay: '',
        pledge: '',
        pay: '',
        labelIds: []
      },
      loading: false,
      disabled: this.$route.query.type === 'view',
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 200,
        disabled: this.$route.query.type === 'view',
        column: [
          {
            label: '房源标题',
            prop: 'title',
            maxlength: 30,
            showWordLimit: true,
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择房源标题",
              trigger: "blur"
            }]
          },
          {
            label: '城市',
            prop: 'cityId',
            type: 'select',
            dicData: [],
            props: {
              label: 'name',
              value: 'id'
            },
            span: 8,
            rules: [{
              required: true,
              message: "请选择城市",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(value === ''){
                  callback(new Error('请选择城市'));
                }else{
                  callback();
                }
              },
              trigger: "change"
            }]
          },
          {
            label: '',
            prop: 'countyId',
            type: 'select',
            dicData: [],
            props: {
              label: 'name',
              value: 'id'
            },
            labelWidth: 0,
            span: 5,
            placeholder: '请选择 区域',
            rules: [{
              required: true,
              message: "请选择区域",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(value === ''){
                  callback(new Error('请选择区域'));
                }else{
                  callback();
                }
              },
              trigger: "change"
            }]
          },
          {
            label: '商圈',
            prop: 'businessId',
            type: "select",
            dicData: [],
            props: {
              label: 'name',
              value: 'id'
            },
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择商圈",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(value === ''){
                  callback(new Error('请选择商圈'));
                }else{
                  callback();
                }
              },
              trigger: "change"
            }],
            disabled: true
          },
          {
            label: '楼盘名称',
            prop: 'buildId',
            type: "select",
            dicData: [],
            props: {
              label: 'name',
              value: 'id'
            },
            filterable: true,
            span: 13,
            row: true,
            placeholder: '选择所选市、区/县的小区名称',
            rules: [{
              required: true,
              message: "请选择楼盘",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(value === ''){
                  callback(new Error('请选择楼盘'));
                }else{
                  callback();
                }
              }
            }],
            disabled: true
          },
          {
            label: '房屋地址',
            prop: 'buildNum',
            span: 13,
            row: true,
            maxlength: 20,
            showWordLimit: true,
            placeholder: '请输入楼栋号',
            rules: [{
              required: true,
              message: "请输入楼栋号",
              trigger: "blur"
            }]
          },
          {
            label: '',
            prop: 'unitNumber',
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请输入单元号",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                // console.log(rule, value)
                if(!value && this.form.isUnit === 1){
                  callback(new Error('请输入单元号或选择无单元号'))
                }else{
                  callback()
                }
              }
            }]
          },
          {
            label: '',
            prop: 'houseNumber',
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请输入门牌号",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                // console.log(rule, value)
                if(!value && this.form.isHouse === 1){
                  callback(new Error('请输入门牌号或选择无房号'))
                }else{
                  callback()
                }
              }
            }]
          },
          {
            label: '出租方式',
            prop: 'rentalMode',
            type: 'select',
            dicData: [
              {
                value: 1,
                label: '整租'
              },
              {
                value: 2,
                label: '合租'
              }
            ],
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择出租方式",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(value === ''){
                  callback(new Error('请选择出租方式'));
                }else{
                  callback();
                }
              }
            }]
          },
          {
            label: '户型',
            prop: 'bedroomOffice',
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择户型",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(value === ''){
                  callback(new Error('请选择户型'));
                }else{
                  callback();
                }
              }
            }]
          },
          {
            label: '出租面积',
            prop: 'rentalArea',
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请输入出租面积",
              trigger: "blur"
            }]
          },
          {
            label: '期望租金',
            prop: 'expectPrice',
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请输入期望租金",
              trigger: "blur"
            }]
          },
          {
            label: '建筑年代',
            prop: 'buildYear',
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请输入建筑年代",
              trigger: "blur"
            }]
          },
          {
            label: '付款方式',
            prop: 'pledgePay',
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择付款方式",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(value === ''){
                  callback(new Error('请选择付款方式'));
                }else{
                  callback();
                }
              }
            }]
          },
          {
            label: '标签',
            prop: 'labelIds',
            type: 'select',
            // 多选
            multiple: true,
            props: {
              label: 'name',
              value: 'id'
            },
            dicData: [],
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择标签",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(value.length === 0){
                  callback(new Error('请选择标签'));
                }else{
                  callback();
                }
              }
            }]
          }
        ]
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      buildName: '',

      cityList: [],
      countyList: [],
      businessList: [],
      buildIdList: []
    }
  },
  async mounted() {
    if(this.$route.query.id){
      this.loading = true;
    }
    await this.getListCity();
    await this.getListLabel();

    await this.$nextTick(() => {
      this.$emit('onload')
    })
  },
  methods: {
    async setForm(form) {
      // console.log(form)
      this.form = {
        ...form,
        bedroomOffice: form.bedroom + '室' + form.office + '厅' + form.toilet + '卫',
        pledgePay: '押' + form.pledge + '付' + form.pay
      };
      await this.handleIsUnit(form.isUnit);
      await this.handleIsHouse(form.isHouse);
      await this.getListCity();
      await this.getListCounty(form.cityId);
      await this.getListBusiness(form.countyId);
      await this.getListBuild()
    },
    formSubmit(){
      return new Promise(resolve => {
        this.$refs.form.validate((valid,done) => {
          if (valid) {
            done();
            resolve(this.form)
          } else {
            done();
            resolve(false)
          }
        });
      })
    },

    async getListCity(){
      const { data } = await listCity();
      // console.log(data);
      this.cityList = data || [];
    },
    async handleCityId(e){
      this.form.countyId = '';
      this.form.businessId = '';
      this.form.buildId = '';
      await this.getListCounty(e)
    },
    // listCounty
    async getListCounty(id){
      if(id){
        const { data } = await listCounty({
          id: id
        });
        this.countyList = data || [];
      }else{
        this.countyList = [];
        this.form.countyId = '';
      }
    },
    async handleCountyId(val){
      this.form.businessId = '';
      this.form.buildId = '';
      await this.getListBusiness(val)
    },
    async getListBusiness(id){
      if(id){
        const { data } = await listBusiness({
          id: id
        });
        // console.log(data)
        this.businessList = data || [];
      }else{
        this.businessList = [];
        this.form.businessId = '';
      }
    },
    async handleBusinessId(e){
      this.form.buildId = '';
      e && await this.getListBuild()
    },
    async getListLabel(){
      const { data } = await listLabel()
      // console.log(data)
      const labelIds = this.findObject(this.formOption.column, 'labelIds');
      labelIds.dicData = data || [];
    },
    // 选择户型---室
    changeBedroom(val){
      // console.log('选择户型---室',val)
      if(val > 0 && this.form.office !== '' && this.form.toilet !== ''){
        this.form.bedroomOffice = val + '室' + this.form.office + '厅' + this.form.toilet + '卫';
      }else{
        this.form.bedroomOffice = ''
      }
      // console.log(this.form.bedroomOffice)
    },
    // 选择户型---厅
    changeOffice(val){
      // console.log('选择户型---厅',val)
      if(val !== '' && this.form.bedroom && this.form.toilet !== ''){
        this.form.bedroomOffice = this.form.bedroom + '室' + val + '厅' + this.form.toilet + '卫';
      }else{
        this.form.bedroomOffice = ''
      }
      // console.log(this.form.bedroomOffice)
    },
    // 选择户型---卫
    changeToilet(val){
      // console.log('选择户型---卫',val)
      if(val !== '' && this.form.bedroom && this.form.office !== ''){
        this.form.bedroomOffice = this.form.bedroom + '室' + this.form.office + '厅' + val + '卫'
      }else{
        this.form.bedroomOffice = ''
      }
      // console.log(this.form.bedroomOffice)
    },
    // 付款方式---押
    changePledge(val){
      // console.log('付款方式---押',val)
      if(val && this.form.pay){
        this.form.pledgePay = '押' + val + '付' + this.form.pay;
      }else{
        this.form.pledgePay = '';
      }
      // console.log(this.form.pledgePay)
    },
    // 付款方式---付
    changePay(val){
      // console.log('付款方式---付',val)
      if(val && this.form.pledge){
        this.form.pledgePay = '押' + this.form.pledge + '付' + val;
      }else{
        this.form.pledgePay = '';
      }
      // console.log(this.form.pledgePay)
    },
    // 限制输入框
    changeBlur(e,text,max){
      // console.log(e.target.value,text)
      // console.log(isNaN(e.target.value))
      const value = e.target.value;
      if(value){
        if(isNaN(e.target.value)){
          e.target.value = ''
          this.form[text] = ''
        }else{
          // console.log(Number(value))
          if(Number(value) > Number(max)){
            this.form[text] = Number(max).toFixed(2)
          }else{
            this.form[text] = Number(value).toFixed(2)
          }
        }
      }else{
        e.target.value = '';
        this.form[text] = ''
      }
    },
    async getListBuild(){
      const { data } = await listBuild({
        cityId: this.form.cityId,
        countyId: this.form.countyId,
        businessId: this.form.businessId,
        name: this.buildName
      })
      // console.log(data)
      this.buildIdList = data || [];
      // 数据加载完成
      console.log('数据加载完成')
      this.loading = false;
    },
    handleIsUnit(val){
      const unitNumber = this.findObject(this.formOption.column, 'unitNumber');
      if(val === 2){
        unitNumber.rules.some(item => {
          if(item.required){
            item.required = false;
            return true;
          }
        })
      }else{
        unitNumber.rules.some(item => {
          if(!item.required){
            item.required = true;
            return true;
          }
        })
      }
    },
    handleIsHouse(val){
      const houseNumber = this.findObject(this.formOption.column, 'houseNumber');
      if(val === 2){
        houseNumber.rules.some(item => {
          if(item.required){
            item.required = false;
            return true;
          }
        })
      }else{
        houseNumber.rules.some(item => {
          if(!item.required){
            item.required = true;
            return true;
          }
        })
      }
    },
  }
}
</script>

<style scoped>
.width_50{
  width: 50%;
}
.width_60{
  width: 60%;
}
/deep/ .el-checkbox .el-checkbox__label{
  font-size: 12px;
  line-height: 13px;
}
</style>